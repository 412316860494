import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const ArticleDetailSkeleton = () => {
    return (
        <Container className="article-detail-wrapper mt-5">
            <Row>
                <Col>
                    <Container fluid>
                        <Row className="article-detail">
                            <Col sm="8" className="flex-wrap">
                                <div className="skeleton-box article-cat mb-4"></div>
                                <div className="skeleton-box article-title mb-4"></div>
                                <hr className="one" />
                                <div className="skeleton-box article-excerpt mb-4"></div>
                                <div className="skeleton-box article-credit-wrapper mb-4"></div>
                                <div className="skeleton-box article-feature-image mb-4"></div>
                                <div className="skeleton-box article-content mb-4"></div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12">
                                <div className="skeleton-box product-wrapper mb-4"></div>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="article-share-btn-footer">
                                <div className="skeleton-box share-icons mb-4"></div>
                            </Col>
                        </Row>
                        <hr className="two" />
                        <Row className="comment-section my-5">
                            <Col>
                                <div className="skeleton-box comment-heading mb-4"></div>
                                <div className="skeleton-box comment-list mb-4"></div>
                            </Col>
                        </Row>
                        <hr className="two pb-2" />
                        <Row className="next-prev-article">
                            <Col xs={6}>
                                <div className="skeleton-box prev-article mb-4"></div>
                            </Col>
                            <Col xs={6}>
                                <div className="skeleton-box next-article mb-4"></div>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
        </Container>
    );
};

export default ArticleDetailSkeleton;