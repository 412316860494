"use client"
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Col, Dropdown } from "react-bootstrap";
import { useGetArticleCategory } from "../service/index";
import CategoryListSkeleton from "./category-list-skeleton";

interface CategoryListProps {
  onClick: () => void;
  selectedCategorySlug: string;
}

interface ArticleCategory {
  id: number;
  name: string;
  parent_id: number | null;
  created_at: string;
  updated_at: string;
  slug: string;
  category_image: string;
}

const CategoryListLg: React.FC<CategoryListProps> = ({
  onClick,
  selectedCategorySlug,
}) => {
  const [categories, setCategories] = useState<ArticleCategory[]>([]);
  const { data, isLoading } = useGetArticleCategory();


  useEffect(() => {
    if (data) {
      const formattedCategories = data.data.map((cat: ArticleCategory) => ({
        id: cat.id,
        name: cat.name,
        slug: cat.slug,
        category_image: cat.category_image,
      }));
      setCategories(formattedCategories);
    }

    // console.log(location.pathname);
    // console.log(location);
  }, [data]);

  const location = useLocation();

  if (isLoading) {
    return <CategoryListSkeleton />
  }

  return (
    <Col sm={3} className="d-none d-lg-block">
      <div className="archive-sidebar">
        {/* <h4>NOUR</h4> */}
        {categories.length ? (
          <>
            {/* <p className="sidebar-label">filed under</p> */}
            <ul>
              {categories.map((category) => (
                <li key={category.id} className={location.pathname.includes(category.slug) ? "activeTab" : ''}>
                  <Link onClick={onClick} to={`/articles/${category.slug}`}>
                    {category.name}
                  </Link>
                </li>
              ))}
            </ul>
          </>
        ) : (
          <p className="no-category-text">No Category Found!</p>
        )}
      </div>
    </Col>
  );
};

export default CategoryListLg;
