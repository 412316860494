import { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import {
  Carousel as SingleCarousel,
  Col,
  Container,
  Row,
} from "react-bootstrap";
import ShareIcons from "./global-components/share-icons";
import { useGetArticleDetails, useGetBlogComments } from "../service/index";
import config from "../config/config";
import calculateTimeAgo from "../utils/helper";
import { Helmet } from "react-helmet";
import Carousel from "react-multi-carousel";

// components
import CommentModal from "./comment-modal";
import ArticleDetailSkeleton from "./article-detail-skeleton";

const ArticleDetail = () => {
  const { slug } = useParams();

  const [articleId, setArticleId] = useState();
  const [articleDetail, setArticleDetail] = useState<any>();
  const [articleCredits, setArticleCredits] = useState([]);
  const [products, setProducts] = useState([]);
  const [prevArticle, setPrevArticle] = useState<any>();
  const [nextArticle, setNextArticle] = useState<any>();
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [replyToCommentId, setReplyToCommentId] = useState<number | null>(null);
  const [blogComments, setBlogComments] = useState([]);

  const {
    data: articleData,
    isLoading,
    isFetching,
    refetch: articleRefetch,
  } = useGetArticleDetails(slug);
  const { data: blogCommentsData, refetch: blogCommentsRefetch } =
    useGetBlogComments(articleDetail?.id);

  // functions
  const handleReply = (commentId: number) => {
    setReplyToCommentId(commentId);
    setShowCommentModal(true);
  };

  const handleAddComment = () => {
    setReplyToCommentId(null);
    setShowCommentModal(true);
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  useEffect(() => {
    if (slug) {
      articleRefetch();
    }
  }, [slug]);

  useEffect(() => {
    if (articleData) {
      setArticleDetail(articleData.data[0]);
      setPrevArticle(articleData.next_prev[0]);
      setNextArticle(articleData.next_prev[1]);

      const credits = JSON.parse(articleData.data[0].written_by);
      setArticleCredits(credits);

      const products = articleData.data[0].products_details
        ? JSON.parse(articleData.data[0].products_details)
        : [];
      setProducts(products);

      setArticleId(articleData.data[0].id);
    }
  }, [articleData]);

  useEffect(() => {
    if (articleId) {
      blogCommentsRefetch();
    }
  }, [articleId]);

  useEffect(() => {
    if (blogCommentsData) {
      setBlogComments(blogCommentsData.data);
    }
  }, [blogCommentsData]);

  if (isLoading || isFetching) {
    return <ArticleDetailSkeleton />;
  }

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{articleDetail?.title}</title>
      </Helmet>
      <CommentModal
        showCommentModal={showCommentModal}
        setShowCommentModal={setShowCommentModal}
        replyToCommentId={replyToCommentId}
        articleId={articleId}
      />
      <Container fluid="md" className="article-detail-wrapper mt-sm-5">
        <Row>
          <Col>
            <Container fluid>
              <Row className="article-detail">
                <Col md="8" sm="12" className="flex-wrap">
                  <h6 className="article-cat text-uppercase">
                    {articleDetail?.blogscategories[0].name}
                  </h6>
                  {articleDetail?.subtitle && (
                    <h2 className="article-subtitle">
                      {articleDetail?.subtitle}
                    </h2>
                  )}
                  <h2 className="article-title">
                    <i>{articleDetail?.title}</i>
                  </h2>

                  <hr className="one" />

                  <p className="article-excerpt">
                    {articleDetail?.shortdescription}
                  </p>

                  <div className="article-credit-wrapper">
                    {articleCredits?.map((credits: any) => (
                      <span key={credits.id}>
                        <i className="credit-title">
                          {credits?.written_by_title}
                        </i>{" "}
                        {credits?.written_by}
                      </span>
                    ))}
                  </div>

                  <div className="article-feature-image">
                    {articleDetail?.selected_option == "image" ? (
                      <img
                        src={
                          config.blogImageURL +
                          articleDetail?.feature_img_article
                        }
                        width={"100%"}
                      />
                    ) : (
                      <iframe
                        className={
                          articleDetail?.video_size === "Landscape"
                            ? "article-feature-video-landscape"
                            : "article-feature-video-vertical"
                        }
                        height="460"
                        width={"100%"}
                        src={`${articleDetail?.video_link}?autoplay=1&mute=1`}
                        title={articleDetail?.title}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      ></iframe>
                    )}
                  </div>

                  <div
                    className="article-content-wrapper"
                    dangerouslySetInnerHTML={{
                      __html: articleDetail?.longdescription,
                    }}
                  />
                </Col>
                {/* <Col sm="4" className="article-sidebar flex-wrap">
                <div className="article-share-div">
                  <p>SHARE THIS</p>
                  <ShareIcons
                    title={articleDetail?.title}
                    media={articleDetail?.feature_img_home}
                  />
                </div>
                <hr className="one" />
                <div className="brand-name">
                  <h5>NOUR</h5>
                  <p className="mb-0 siderbar-label">FILED UNDER</p>
                  <p className="cat-name text-uppercase">
                    {articleDetail?.blogscategories[0].name}
                  </p>
                </div>
              </Col> */}
              </Row>

              {products.length != 0 && (
                <Row>
                  <Col sm="12">
                    <div className="article-product-main-wrapper">
                      <h2>
                        {articleDetail?.product_heading
                          ? articleDetail?.product_heading
                          : "SHOP MY BAG"}
                      </h2>
                      {/* <hr className="heading-hr" /> */}
                      {products.map((product: any, index) => (
                        <Row className="product-row-wrapper" key={index}>
                          <Col sm="4" className="align-self-end">
                            <div className="numbers-container">
                              <span className="number-one">{index + 1}</span>
                              <span className="number-devider">/</span>
                              <span className="number-two">
                                {products.length}
                              </span>
                            </div>
                            <div className="product-description">
                              {product.product_shortdescription}
                            </div>
                            <div className="product-brand-name">
                              {product.product_category}
                            </div>
                            <div className="product-name">
                              {product.product_title}
                            </div>
                            <div className="price-product">
                              AED
                              <span className="main-price">
                                {product.product_price}
                              </span>
                            </div>
                            <a
                              className="shop-now-btn"
                              href={product.product_url}
                            >
                              Shop Now
                            </a>
                            <hr
                              className={`w-100 d-md-none ${
                                index + 1 == products.length ? "d-none" : ""
                              }`}
                            />
                          </Col>
                          <Col
                            xs="12"
                            sm="8"
                            className="product-media mt-3 d-flex align-items-center flex-column"
                          >
                            <SingleCarousel className="w-sm-50">
                              {product?.product_image?.map(
                                (image: any, index: number) => (
                                  <SingleCarousel.Item key={index}>
                                    <img
                                      src={config.productImageURL + image}
                                      alt={product.product_title}
                                      width={"100%"}
                                    />
                                  </SingleCarousel.Item>
                                )
                              )}
                            </SingleCarousel>
                            <hr className="mb-0 w-100 d-none d-md-block" />
                          </Col>
                        </Row>
                      ))}
                    </div>
                  </Col>
                </Row>
              )}

              <Row>
                <Col className="article-share-btn-footer">
                  <ShareIcons
                    title={articleDetail?.title}
                    media={articleDetail?.feature_img_home}
                  />
                </Col>
              </Row>

              <hr className="two" />

              <Row className="comment-section my-5">
                <Col>
                  <div className="comment-heading-container">
                    <h3 className="">REAL PEOPLE, REAL REVIEWS</h3>
                    {blogComments && blogComments.length > 0 ? (
                      <button
                        className="mt-4 add-comment-btn"
                        onClick={handleAddComment}
                      >
                        Add Comment
                      </button>
                    ) : null}
                  </div>
                  {blogComments && blogComments.length > 0 ? (
                    <Carousel responsive={responsive} className="">
                      {blogComments.map((comment: any, index) => (
                        // <Carousel.Item key={index}>
                        <div key={comment.id} className="comment-item mt-4">
                          {/* <div className="d-flex align-items-center mb-2">
                            <div className="initial-circle me-2">
                              {comment.name.charAt(0).toUpperCase()}
                            </div>
                            <div>
                              <h5 className="mb-0 text-capitalize">
                                {comment.name}
                              </h5>
                              <small>
                                {comment.email} •{" "}
                                {calculateTimeAgo(comment.created_at)}
                              </small>
                            </div>
                          </div> */}
                          <h2>{comment.subject}</h2>
                          <p className="text-capitalize">{comment.message}</p>
                          <small>
                            {/* {comment.email} •{" "} */}
                            {calculateTimeAgo(comment.created_at)}
                          </small>
                          <h5 className="mb-0 text-capitalize mt-3">
                            {comment.name}
                          </h5>
                        </div>
                        // </Carousel.Item>
                      ))}
                    </Carousel>
                  ) : (
                    <p>Be the first one to comment!</p>
                  )}
                  {blogComments.length === 0 ? (
                    <button
                      className="mt-4 add-comment-btn"
                      onClick={handleAddComment}
                    >
                      Add Comment
                    </button>
                  ) : null}
                </Col>
              </Row>

              <hr className="two pb-2" />

              <Row className="next-prev-article">
                {prevArticle && (
                  <Col xs={6}>
                    <Link
                      to={`/article-detail/${prevArticle.blogscategories[0].slug}/${prevArticle.slug}`}
                      state={{ id: prevArticle.id }}
                      className="next-prev-article-link"
                    >
                      <img
                        src={config.blogImageURL + prevArticle.feature_img_home}
                        width="40%"
                      />
                      <div className="meta-data">
                        <p className="mb-2">
                          <i>filed under</i>{" "}
                          {prevArticle.blogscategories[0].name}
                        </p>
                        <h3>{prevArticle.title}</h3>
                      </div>
                    </Link>
                  </Col>
                )}

                {nextArticle && (
                  <Col xs={6}>
                    <Link
                      to={`/article-detail/${nextArticle.blogscategories[0].slug}/${nextArticle.slug}`}
                      state={{ id: nextArticle.id }}
                      className="next-prev-article-link"
                    >
                      <img
                        src={
                          config.blogImageURL + nextArticle?.feature_img_home
                        }
                        width="40%"
                      />
                      <div className="meta-data">
                        <p className="mb-2">
                          <i>filed under</i>{" "}
                          {nextArticle?.blogscategories[0].name}
                        </p>
                        <h3>{nextArticle?.title}</h3>
                      </div>
                    </Link>
                  </Col>
                )}
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ArticleDetail;
