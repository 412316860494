import { Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useAddBlogComments, useReplyBlogComments } from "../service";
import { toast } from "react-toastify";

const CommentModal = ({
  showCommentModal,
  setShowCommentModal,
  replyToCommentId,
  articleId,
}: any) => {
  const { mutate: addCommentMutation } = useAddBlogComments();
  const { mutate: replyCommentMutation } = useReplyBlogComments();

  const schema = yup.object().shape({
    name: yup.string().required("Name is required"),
    email: yup.string().email("Invalid email").required("Email is required"),
    subject: yup.string().required("Subject is required"),
    message: yup.string().required("Comment is required"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const handleCloseCommentModal = () => {
    setShowCommentModal(false);
    reset();
  };

  const onSubmitComment = async (data: any) => {
    let body;
    if (replyToCommentId !== null) {
      body = {
        name: data.name,
        email: data.email,
        message: data.message,
        post_id: articleId,
        parent_id: replyToCommentId,
        subject: data.subject,
      };

      replyCommentMutation(body, {
        onSuccess: () => {
          handleCloseCommentModal();
          toast.success("Comment added successfully");
        },
        onError: () => {
          toast.error("Failed to add comment");
        },
      });
    } else {
      body = {
        name: data.name,
        email: data.email,
        subject: data.subject,
        message: data.message,
        post_id: articleId,
      };

      addCommentMutation(body, {
        onSuccess: () => {
          handleCloseCommentModal();
          toast.success("Comment added successfully");
        },
        onError: () => {
          toast.error("Failed to add comment");
        },
      });
    }
  };

  return (
    <Modal
      show={showCommentModal}
      onHide={handleCloseCommentModal}
      className="comment-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {replyToCommentId ? "Reply to Comment" : "Add Comment"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmitComment)}>
          <Form.Group className="mb-3" controlId="commentForm.ControlInput1">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Your Name"
              {...register("name")}
              isInvalid={!!errors.name}
            />
            <Form.Control.Feedback type="invalid">
              {errors.name?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="commentForm.ControlInput2">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="name@example.com"
              {...register("email")}
              isInvalid={!!errors.email}
            />
            <Form.Control.Feedback type="invalid">
              {errors.email?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="commentForm.ControlInput2">
            <Form.Label>Subject</Form.Label>
            <Form.Control
              type="text"
              // placeholder="name@example.com"
              {...register("subject")}
              isInvalid={!!errors.subject}
            />
            <Form.Control.Feedback type="invalid">
              {errors.subject?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="commentForm.ControlTextarea1">
            <Form.Label>Comment</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              {...register("message")}
              isInvalid={!!errors.message}
            />
            <Form.Control.Feedback type="invalid">
              {errors.message?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <button type="submit" className="submit-comment-btn">
            Submit Comment
          </button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CommentModal;
