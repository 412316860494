import { Col, Form, Row } from "react-bootstrap";

interface PaginationProps {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  totalPages: number;
  disablePrev: boolean;
  disableNext: boolean;
}

const Pagination = ({
  page,
  setPage,
  totalPages,
  disablePrev,
  disableNext,
}: PaginationProps) => {
  const renderPageNumbers = () => {
    const pages = [];

    // Always show the first page
    if (page !== 1) {
      pages.push(
        <li key={1} className={`page-item ${page === 1 ? "active" : ""}`}>
          <button className="page-link" onClick={() => setPage(1)}>
            1
          </button>
        </li>
      );
    }

    // If the current page is greater than 3, show ellipsis after the first page
    if (page > 3) {
      pages.push(
        <li key="start-ellipsis" className="page-item disabled">
          <span className="page-link">...</span>
        </li>
      );
    }

    // Show the previous page (if it's within bounds)
    if (page > 2) {
      pages.push(
        <li key={page - 1} className="page-item">
          <button className="page-link" onClick={() => setPage(page - 1)}>
            {page - 1}
          </button>
        </li>
      );
    }

    // Show the current active page
    pages.push(
      <li key={page} className="page-item active">
        <button className="page-link">{page}</button>
      </li>
    );

    // Show the next page (if it's within bounds)
    if (page < totalPages - 1) {
      pages.push(
        <li key={page + 1} className="page-item">
          <button className="page-link" onClick={() => setPage(page + 1)}>
            {page + 1}
          </button>
        </li>
      );
    }

    // If the current page is more than 2 pages away from the last, show ellipsis
    if (page < totalPages - 2) {
      pages.push(
        <li key="end-ellipsis" className="page-item disabled">
          <span className="page-link">...</span>
        </li>
      );
    }

    // Always show the last page
    if (page !== totalPages) {
      pages.push(
        <li
          key={totalPages}
          className={`page-item ${page === totalPages ? "active" : ""}`}
        >
          <button className="page-link" onClick={() => setPage(totalPages)}>
            {totalPages}
          </button>
        </li>
      );
    }

    return pages;
  };

  return (
    <Row className="pb-5 justify-content-center d-flex w-100">
      <Col sm="auto">
        <nav>
          <ul className="pagination justify-content-center">
            <li className={`page-item ${disablePrev ? "disabled" : ""}`}>
              <button
                className="page-link-arrows"
                onClick={() => setPage(page - 1)}
                disabled={disablePrev}
                style={{ marginRight: "32px" }}
              >
                PREVIOUS
              </button>
            </li>
            {renderPageNumbers()}
            <li className={`page-item ${disableNext ? "disabled" : ""}`}>
              <button
                className="page-link-arrows"
                onClick={() => setPage(page + 1)}
                disabled={disableNext}
                style={{ marginLeft: "14px" }}
              >
                NEXT
              </button>
            </li>
          </ul>
        </nav>
      </Col>
      {/* <Col xs="auto" className="mb-3 mb-sm-0 justify-content-center">
        <Form.Group controlId="articlesPerPage">
          <Form.Select
            value={limit}
            onChange={(e) => {
              setLimit(Number(e.target.value));
              setPage(1);
            }}
            aria-label="Items per page"
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={15}>15</option>
            <option value={20}>20</option>
          </Form.Select>
        </Form.Group>
      </Col> */}
    </Row>
  );
};

export default Pagination;
