import { Container, Row, Col } from "react-bootstrap";

const ArticleArchiveSkelecton = () => {
  return (
    <Container className="skeleton-product-display">
      <Row>
        {[1, 2, 3].map((item) => (
          <Col md={4} key={item}>
            <div className="skeleton-product-item">
              <div className="skeleton-image"></div>
              <div className="skeleton-title"></div>
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default ArticleArchiveSkelecton;
