import { useEffect, useState } from "react";
import {
  Container,
  Nav,
  Navbar,
  Offcanvas,
  Form,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";
import { useCookies } from "react-cookie";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useGetSearchArticle } from "../../service";
import { useDebounce } from "../../hooks/useDebounce";
import config from "../../config/config";

// component
import ModalComponent from "./modal";
import DataNotFound from "./data-not-found";

interface TopbarContent {
  id: number;
  entity_name: string;
  entity_data: string;
  entity_link: string;
  created_at: string;
  updated_at: string;
}

interface Menu {
  id: number;
  title: string;
  url: string;
}

interface Entity {
  id: number;
  entity_name: string;
  entity_data: string;
  entity_link: string;
  created_at: string | null;
  updated_at: string | null;
}

interface HeaderProps {
  topbarContent: TopbarContent[];
  menu: Menu[];
  navbarLink: Entity[];
  cookieExpiration: Entity | null;
}

const Header: React.FC<HeaderProps> = ({
  menu,
  navbarLink,
  topbarContent,
  cookieExpiration,
}) => {
  const [show, setShow] = useState(false);
  const [searchBar, setSearchBar] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [searchedArticleList, setSearchedArticleList] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [showResults, setShowResults] = useState(false);

  const debounceSearch = useDebounce(searchKeyword);

  const { data, isLoading, isFetching, refetch } = useGetSearchArticle(
    debounceSearch,
    1
  );

  const [cookies, setCookie] = useCookies(["name", "modalShown"]);

  const navigate = useNavigate();
  const location = useLocation();

  const handleSearchInputChange = (e: any) => {
    setSearchKeyword(e.target.value);
    setIsSearching(true);
    setShowResults(false);
    if (e.target.value === "") {
      setSearchedArticleList([]);
      setIsSearching(false);
    }
  };

  const handleClose = () => {
    setShow(false);
    const expireDate = new Date();
    expireDate.setDate(
      expireDate.getDate() + Number(cookieExpiration?.entity_link)
    );
    setCookie("modalShown", "true", { expires: expireDate });
  };

  useEffect(() => {
    setTimeout(() => {
      const isModalShown = cookies.modalShown;
      if (!isModalShown) {
        setShow(true);
      }
    }, 3000);
  }, [cookies]);

  useEffect(() => {
    if (debounceSearch) {
      refetch();
    } else {
      setIsSearching(false);
      setShowResults(false);
    }
  }, [debounceSearch]);

  useEffect(() => {
    if (data) {
      setSearchedArticleList(data.data.data);
      setIsSearching(false);
      setTimeout(() => setShowResults(true), 500);
    }
  }, [data]);

  return (
    <>
      <ModalComponent show={show} onHide={handleClose} />
      <div
        className={`sticky-header-main ${
          location.pathname.includes("article-detail")
            ? "article-detail-page"
            : ""
        }`}
      >
        <div className="topbar">
          <Container fluid>
            <Row className="announcement-bar">
              <Col className="py-2">
                <p className={"mb-0 text-center primaryFont"}>
                  {topbarContent[0]?.entity_link}
                </p>
              </Col>
            </Row>
          </Container>
        </div>
        <Navbar expand={"md"} className="main-nav-container flex-column pt-0">
          <Container fluid className="bg-theme-dark main-center-bar">
            <Row className="w-100">
              <Col xs={3} sm={4} className="">
                <Navbar.Toggle
                  aria-controls={`offcanvasNavbar-expand-${"md"}`}
                />
                <div id="google_translate_element"> </div>
              </Col>
              <Col xs={6} sm={4} className="text-center">
                <Navbar.Brand href="/">
                  <img src={"/assets/imgs/Logo/red_logo.png"} width="150" />
                </Navbar.Brand>
              </Col>
              <Col xs={3} sm={4} className="text-end nav-right-container">
                <button
                  onClick={() => setSearchBar(!searchBar)}
                  className="search-btn"
                >
                  <p className="d-xs-none mb-0">Search</p>
                  <svg
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="122.879px"
                    height="119.799px"
                    viewBox="0 0 122.879 119.799"
                    enableBackground="new 0 0 122.879 119.799"
                  >
                    <g>
                      <path d="M49.988,0h0.016v0.007C63.803,0.011,76.298,5.608,85.34,14.652c9.027,9.031,14.619,21.515,14.628,35.303h0.007v0.033v0.04 h-0.007c-0.005,5.557-0.917,10.905-2.594,15.892c-0.281,0.837-0.575,1.641-0.877,2.409v0.007c-1.446,3.66-3.315,7.12-5.547,10.307 l29.082,26.139l0.018,0.016l0.157,0.146l0.011,0.011c1.642,1.563,2.536,3.656,2.649,5.78c0.11,2.1-0.543,4.248-1.979,5.971 l-0.011,0.016l-0.175,0.203l-0.035,0.035l-0.146,0.16l-0.016,0.021c-1.565,1.642-3.654,2.534-5.78,2.646 c-2.097,0.111-4.247-0.54-5.971-1.978l-0.015-0.011l-0.204-0.175l-0.029-0.024L78.761,90.865c-0.88,0.62-1.778,1.209-2.687,1.765 c-1.233,0.755-2.51,1.466-3.813,2.115c-6.699,3.342-14.269,5.222-22.272,5.222v0.007h-0.016v-0.007 c-13.799-0.004-26.296-5.601-35.338-14.645C5.605,76.291,0.016,63.805,0.007,50.021H0v-0.033v-0.016h0.007 c0.004-13.799,5.601-26.296,14.645-35.338C23.683,5.608,36.167,0.016,49.955,0.007V0H49.988L49.988,0z M50.004,11.21v0.007h-0.016 h-0.033V11.21c-10.686,0.007-20.372,4.35-27.384,11.359C15.56,29.578,11.213,39.274,11.21,49.973h0.007v0.016v0.033H11.21 c0.007,10.686,4.347,20.367,11.359,27.381c7.009,7.012,16.705,11.359,27.403,11.361v-0.007h0.016h0.033v0.007 c10.686-0.007,20.368-4.348,27.382-11.359c7.011-7.009,11.358-16.702,11.36-27.4h-0.006v-0.016v-0.033h0.006 c-0.006-10.686-4.35-20.372-11.358-27.384C70.396,15.56,60.703,11.213,50.004,11.21L50.004,11.21z" />
                    </g>
                  </svg>
                </button>
                {navbarLink?.map((link, index) => (
                  <div key={index}>
                    <Link to={link.entity_link} className="d-xs-none">
                      {link.entity_data}
                    </Link>
                    {index === navbarLink.length - 1 && (
                      <Link to={link.entity_link} className="d-md-none">
                        <svg
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                        >
                          <path
                            d="M0 0 C1.32 0 2.64 0 4 0 C4 0.66 4 1.32 4 2 C7.96 2 11.92 2 16 2 C14.125 10.625 14.125 10.625 13 14 C12.01 14 11.02 14 10 14 C10 13.01 10 12.02 10 11 C9.01 12.485 9.01 12.485 8 14 C7.01 13.67 6.02 13.34 5 13 C4.12109375 10.56640625 4.12109375 10.56640625 3.4375 7.5625 C2.56463461 3.32840356 2.56463461 3.32840356 0 0 Z M4 3 C4.66 4.65 5.32 6.3 6 8 C8.31 8 10.62 8 13 8 C13.33 6.35 13.66 4.7 14 3 C10.7 3 7.4 3 4 3 Z "
                            fill="#FFFFFF"
                            transform="translate(0,1)"
                          />
                        </svg>
                      </Link>
                    )}
                  </div>
                ))}
              </Col>
            </Row>
            {/* <Navbar.Brand href="#"><img src={config?.blogImageURL + "/public/assets/imgs/Logo/red_logo.png"} width="300" /></Navbar.Brand> */}
          </Container>

          <Container
            fluid
            className="py-1 navigation-container justify-content-center d-xs-none"
          >
            <Row>
              <Col sm={12} className="">
                {/* <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${"md"}`} /> */}
                <Navbar.Offcanvas
                  id={`offcanvasNavbar-expand-${"md"}`}
                  aria-labelledby={`offcanvasNavbarLabel-expand-${"md"}`}
                  placement="end"
                >
                  <Offcanvas.Header closeButton>
                    {/* <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${"md"}`}>
                      Menu
                    </Offcanvas.Title> */}
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <Nav className="justify-content-end flex-grow-1 pe-3 navigation-menu">
                      {menu?.map((item: any) => (
                        <Nav.Link href={item?.url} key={item.id}>
                          {item?.title}
                        </Nav.Link>
                      ))}
                      {/* <NavDropdown
                            title="Dropdown"
                            id={`offcanvasNavbarDropdown-expand-${"md"}`}
                          >
                            <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
                            <NavDropdown.Item href="#action4">
                              Another action
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="#action5">
                              Something else here
                            </NavDropdown.Item>
                          </NavDropdown> */}
                    </Nav>
                    <p className="nav-brand-name d-xs-none">
                      <span>|</span>
                      <span className="ms-3 brand-name">EDITORIAL</span>
                    </p>
                  </Offcanvas.Body>
                </Navbar.Offcanvas>
              </Col>
            </Row>
          </Container>
          <Container
            fluid
            className={`search-bar-container top-xs-50 top-sm-90 top-md-120 ${
              searchBar ? "active" : ""
            }`}
          >
            <Row className="search-bar-padding w-sm-40 w-xs-100 d-flex align-items-center justify-content-between">
              <Col xs={11}>
                <Form className="d-flex search-form">
                  <Form.Control
                    type="search"
                    placeholder="Search"
                    className="me-2 search-field"
                    aria-label="Search"
                    value={searchKeyword}
                    onChange={(e) => handleSearchInputChange(e)}
                  />
                </Form>
              </Col>
              <Col xs={1} className="p-0">
                <button
                  className="btn-close"
                  aria-label="Close"
                  onClick={() => setSearchBar(false)}
                ></button>
              </Col>
            </Row>
            {isSearching && (
              <Row className="search-article-container py-4">
                <Col sm={12} className="d-flex justify-content-center">
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </Col>
              </Row>
            )}
            {showResults &&
              (searchedArticleList.length ? (
                <Row className="search-article-container py-4">
                  <Col sm={12} className="search-article-list">
                    {searchedArticleList
                      .slice(0, 4)
                      .map((article: any, index) => (
                        <Link
                          onClick={() => setSearchBar(false)}
                          to={`/article-detail/${article.blogscategories[0].slug}/${article.slug}`}
                          state={{ id: article.id }}
                          className="search-article-card"
                          key={index}
                        >
                          <div className="left-art-container">
                            <img
                              src={
                                config.blogImageURL + article.feature_img_home
                              }
                              className="w-100"
                            />
                          </div>
                          <div className="article-meta-container">
                            <div className="cat-container">
                              <i>Filed Under</i>
                              <p className="list-cat">
                                {article.blogscategories[0].name}
                              </p>
                            </div>
                            <h6 className="article-heading text-uppercase">
                              {article.title}
                            </h6>
                            <p className="article-btn">READ & SHOP THE FILE</p>
                          </div>
                        </Link>
                      ))}
                  </Col>
                  <Col
                    xs={12}
                    className="d-flex justify-content-center mt-3 mt-md-5"
                  >
                    <button
                      onClick={() => (
                        setSearchBar(false),
                        setSearchKeyword(""),
                        setSearchedArticleList([]),
                        navigate(`/articles/?search=${searchKeyword}`, {
                          state: { searchedArticleList: searchedArticleList },
                        })
                      )}
                      className="view-all-btn"
                    >
                      VIEW ALL
                    </button>
                  </Col>
                </Row>
              ) : (
                searchKeyword && (
                  <Row className="search-article-container py-4">
                    <Col sm={12} className="search-article-list">
                      <DataNotFound />
                    </Col>
                  </Row>
                )
              ))}
          </Container>
        </Navbar>
      </div>
      {/* ))} */}
    </>
  );
};

export default Header;
