import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { useGetPopupDetail, useSubscribeToNewsletter } from "../../service";
import config from "../../config/config";

const ModalComponent = (props: any) => {
  interface PopupContent {
    id: number;
    img_url: string;
    title: string;
    subtitle: string;
    placeholder_text: string;
    btn_label: string;
    btn_link: string;
    note: string;
    success_message: string;
  }

  interface SubscribeToNewsletterData {
    subscribed_emails: string;
  }

  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [popupContent, setPopupContent] = useState<PopupContent>();

  const { data } = useGetPopupDetail();
  const { mutate } = useSubscribeToNewsletter();

  const validateEmail = (email: string) => {
    if (!email.trim()) {
      return "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      return "Email is invalid";
    }
  };

  const handleChange = (e: any) => {
    setEmail(e.target.value);
    const validate = validateEmail(email);
    if (validate) {
      setError(validate);
    } else {
      setError("");
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const validate = validateEmail(email);
    if (validate) {
      setError(validate);
    } else {
      setError("");
      const data: SubscribeToNewsletterData = {
        subscribed_emails: email,
      };
      mutate(data, {
        onSuccess: async () => {
          setEmail("");
          toast.dismiss();
          toast.success(popupContent?.success_message);
          props.onHide();
          if (popupContent?.btn_link !== "#") {
            let redirect_link = popupContent ? popupContent.btn_link : "";
            window.location.href = redirect_link;
          }
        },
      });
    }
  };

  useEffect(() => {
    if (data) {
      setPopupContent(data.data);
    }
  }, [data]);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="custom-modal-container"
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <Container>
          <Row>
            <Col
              sm="6"
              className="image-column"
              style={{
                backgroundImage: `url(${config.popupImageURL}${popupContent?.img_url})`,
              }}
            ></Col>
            <Col sm="6" className="modal-content-wrapper">
              <h4>{popupContent?.title}</h4>
              <h5>{popupContent?.subtitle}</h5>
              <form onSubmit={(e) => handleSubmit(e)}>
                <div style={{ minHeight: "60px" }}>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => handleChange(e)}
                    placeholder={popupContent?.placeholder_text}
                  />
                  {error && (
                    <p className="text-danger text-start mb-0">{error}</p>
                  )}
                </div>
                <button type="submit" className="submit-btn">
                  {popupContent?.btn_label}
                </button>
              </form>
              <p className="note-text">{popupContent?.note}</p>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalComponent;
