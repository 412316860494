import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

const HomeBlogsSkeleton = () => {
  return (
    <div className="archive-main-content-wrapper">
      <div className="archive-main-content">
        {[...Array(5)].map((_, index) => (
          <div className="article-list-container skeleton-box" key={index} style={{ marginBottom: "30px" }}>
            <div className="left-art-container skeleton-box" style={{ width: "30%", height: "200px" }}></div>
            <div className="article-meta-container" style={{ width: "70%" }}>
              <div className="skeleton-box" style={{ height: "20px", width: "150px", marginBottom: "10px" }}></div>
              <div className="skeleton-box" style={{ height: "30px", width: "80%", marginBottom: "15px" }}></div>
              <div className="skeleton-box" style={{ height: "60px", width: "100%", marginBottom: "20px" }}></div>
              <div className="skeleton-box" style={{ height: "40px", width: "150px" }}></div>
            </div>
          </div>
        ))}
      </div>
      <div className="skeleton-box" style={{ height: "40px", width: "200px", margin: "0 auto" }}></div>
    </div>
  );
};

export default HomeBlogsSkeleton;
