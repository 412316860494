import React from 'react'
import { Col } from 'react-bootstrap'

const CategoryListSkeleton = () => {
    return (
        <Col lg={3} className="d-none d-lg-block">
            <div className="archive-sidebar">
                <div className="skeleton-box" style={{ height: "400px", marginBottom: "20px" }}></div>
            </div>
        </Col>
    )
}

export default CategoryListSkeleton
