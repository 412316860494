import { useEffect, useState } from "react";
import { Carousel, Col, Container, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
// import { toast } from "react-toastify";
import { useGetHomePageBanner, useGetHomepageArticles } from "../service/index";
import config from "../config/config";
import { BlogPost } from "../utils/type";

// components
import DataNotFound from "./global-components/data-not-found";
import Pagination from "./global-components/pagination";
import CategoryListSm from "./category-list-sm";
import CategoryListLg from "./category-list";
import HomeSliderSkeleton from "./home-slider-skeleton";
import HomeBlogsSkeleton from "./home-blogs-skeleton";

const Home = () => {
  const [articlesList, setArticlesList] = useState([]);
  const [banners, setBanners] = useState<BlogPost[]>([]);
  const [page, setPage] = useState(1);
  const [disablePrev, setDisablePrev] = useState(false);
  const [disableNext, setDisableNext] = useState(false);
  const limit = 10;
  const [totalPages, setTotalPages] = useState(1);

  const { data: listData, isLoading: listDataLoading } = useGetHomepageArticles(
    page,
    limit
  );
  const { data: bannerList, isLoading: bannerListLoading } =
    useGetHomePageBanner();

  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % banners.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + banners.length) % banners.length);
  };

  useEffect(() => {
    if (listData) {
      setArticlesList(listData.data.data);
      setDisablePrev(listData.data.current_page === 1);
      setDisableNext(listData.data.last_page === page);
      setTotalPages(listData.data.last_page);
    }
  }, [listData, page]);

  useEffect(() => {
    if (bannerList) {
      setBanners(bannerList.data);
    }
  }, [bannerList]);

  return (
    <div>
      <CategoryListSm onClick={() => {}} selectedCategorySlug="" />
      <Container fluid className={`${bannerListLoading && "px-0"}`}>
        {bannerListLoading ? (
          <HomeSliderSkeleton />
        ) : banners?.length ? (
          <div>
            <h3 className="carousel-content-title d-lg-none text-center mb-3">
              NOUR
            </h3>
            <div className="hero-slider-container">
              <div
                className="d-flex flex-column hero-slider-info-wrapper"
                style={{ width: "50%" }}
              >
                <h3 className="carousel-content-title d-none d-lg-block">
                  NOUR
                </h3>
                <div className="hero-slider-info-block">
                  <div className="article-meta-container">
                    <div className="cat-container">
                      <i className="me-2">Filed Under</i>
                      <span className="list-cat text-uppercase">
                        {banners[currentSlide]?.blogscategories[0].name}
                      </span>
                    </div>
                    <Link
                      to={`/article-detail/${banners[currentSlide]?.blogscategories[0].slug}/${banners[currentSlide]?.slug}`}
                      state={{ id: banners[currentSlide]?.id }}
                      className="article-title-link"
                    >
                      <h3 className="banner-article-heading">
                        {banners[currentSlide]?.title}
                      </h3>
                    </Link>
                    <p className="article-desc">
                      {banners[currentSlide]?.shortdescription}
                    </p>
                    <div className="d-flex justify-content-between align-items-center">
                      <Link
                        to={`/article-detail/${banners[currentSlide]?.blogscategories[0].slug}/${banners[currentSlide]?.slug}`}
                        state={{ id: banners[currentSlide]?.id }}
                        className="article-btn"
                      >
                        READ &amp; SHOP THE FILE
                      </Link>
                      <div className="d-flex gap-1">
                        <button onClick={prevSlide} className="btn ">
                          &lt;
                        </button>
                        <button onClick={nextSlide} className="btn ">
                          &gt;
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hero-slider-image-container">
                <div className="hero-slider-img1">
                  <img
                    src={
                      config.blogImageURL +
                      banners[currentSlide]?.feature_img_home
                    }
                    alt={banners[currentSlide]?.title}
                    width={"100%"}
                    height={"100%"}
                  />
                </div>
                <div className="hero-slider-img2">
                  <img
                    src={
                      config.blogImageURL +
                      banners[(currentSlide + 1) % banners.length]
                        ?.feature_img_home
                    }
                    alt={banners[(currentSlide + 1) % banners.length]?.title}
                    width={"100%"}
                    height={"100%"}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="no-banner-container">
            <div>
              <img src="/assets/imgs/Logo/red_logo.png" alt="nour" />
            </div>
          </div>
        )}
        {/* <hr className="mb-0 w-100" /> */}
        <Row className="home-body">
          <CategoryListLg onClick={() => {}} selectedCategorySlug="" />
          <Col lg={9} className="px-0">
            {listDataLoading ? (
              <HomeBlogsSkeleton />
            ) : articlesList?.length ? (
              <div className="archive-main-content-wrapper">
                <div className="archive-main-content">
                  {articlesList?.map((article: any) => {
                    return (
                      <div className="article-list-container" key={article.id}>
                        <Link
                          to={`/article-detail/${article.blogscategories[0].slug}/${article.slug}`}
                          state={{ id: article.id }}
                          className="left-art-container"
                        >
                          <img
                            src={config.blogImageURL + article.feature_img_home}
                            alt={article.title}
                          />
                        </Link>
                        <div className="article-meta-container">
                          <div className="cat-container">
                            <i>Filed Under</i>
                            <p className="list-cat text-uppercase">
                              {article.blogscategories[0].name}
                            </p>
                          </div>
                          <Link
                            to={`/article-detail/${article.blogscategories[0].slug}/${article.slug}`}
                            state={{ id: article.id }}
                            className="article-title-link"
                          >
                            <h3 className="article-heading">{article.title}</h3>
                          </Link>
                          <p className="article-desc">
                            {article.shortdescription}
                          </p>
                          <Link
                            to={`/article-detail/${article.blogscategories[0].slug}/${article.slug}`}
                            state={{ id: article.id }}
                            className="article-btn"
                          >
                            READ & SHOP THE FILE
                          </Link>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <Pagination
                  page={page}
                  setPage={setPage}
                  totalPages={totalPages}
                  disablePrev={disablePrev}
                  disableNext={disableNext}
                />
              </div>
            ) : (
              <DataNotFound />
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default Home;
