const DataNotFound = () => {
  return (
    <div className="no-data-container">
      <div className="">
        <img
          src="/assets/imgs/other/no-data-found.png"
          alt="No data found"
          width={300}
        />
      </div>
      <div>
        <h3 className="no-data-heading">OOPS!!</h3>
        <p className="text-center">No article found</p>
      </div>
    </div>
  );
};

export default DataNotFound;
