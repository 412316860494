import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Form } from "react-bootstrap";
import { useGetArticleCategory } from "../service/index";

interface CategoryListProps {
  onClick: () => void;
  selectedCategorySlug: string;
}

interface ArticleCategory {
  id: number;
  name: string;
  parent_id: number | null;
  created_at: string;
  updated_at: string;
  slug: string;
  category_image: string;
}

const CategoryListSm: React.FC<CategoryListProps> = ({
  onClick,
  selectedCategorySlug,
}) => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState<ArticleCategory[]>([]);
  const [categorySelected, setCategorySelected] = useState<string>(
    selectedCategorySlug ? selectedCategorySlug : "Select Category"
  );

  const { data, isLoading } = useGetArticleCategory();

  const handleSelect = (categorySlug: string) => {
    onClick();
    navigate(`/articles/${categorySlug}`);
  };

  useEffect(() => {
    if (data) {
      const formattedCategories = data.data.map((cat: ArticleCategory) => ({
        id: cat.id,
        name: cat.name,
        slug: cat.slug,
        category_image: cat.category_image,
      }));
      setCategories(formattedCategories);
    }
  }, [data]);

  useEffect(() => {
    if (selectedCategorySlug) {
      setCategorySelected(selectedCategorySlug);
    }
  }, [selectedCategorySlug]);

  return (
    <Col sm={12} className="mobile-category-list d-lg-none">
      {categories.length ? (
        <>
          <p className="sidebar-label mt-3">filed under</p>
          <Form.Group controlId="category">
            <Form.Select
              value={categorySelected}
              onChange={(e) => {
                handleSelect(e.target.value);
              }}
              aria-label="category"
              className={categorySelected ? "selected-category-name" : ""}
            >
              <option value="" disabled={!!selectedCategorySlug}>
                Select Category
              </option>
              {categories.map((category) => (
                <option key={category.id} value={category.slug}>
                  {category.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </>
      ) : (
        <p className="no-category-text">No Category Found!</p>
      )}
    </Col>
  );
};

export default CategoryListSm;
